import React from 'react';
import { useFeatureFlag } from '@pulselive/launchdarkly-sdk-wrapper';
import {
    Icon,
    NavigationScreen,
    PageWrapper
} from '@pulselive/core-product-components';

export function Home() {
    const routes = [
        {
            path: '/preferences',
            heading: 'Preferences',
            description:
                'Capture user consent and preferences to craft a more personalised fan experience',
            Icon: (props) => <Icon type="segments" {...props} />
        }
    ];

    const metricsDashboard = useFeatureFlag('metrics-dashboard');
    if (metricsDashboard) {
        routes.push({
            path: '/metrics',
            heading: 'Metrics',
            description:
                'Gain insights into your user base with metrics on activity',
            Icon: (props) => <Icon type="trending-up" {...props} />
        });
    }

    return (
        <PageWrapper>
            <NavigationScreen
                heading="Pulselive ID"
                description=""
                routes={routes}
            />
        </PageWrapper>
    );
}
