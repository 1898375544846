import './set-public-path';
import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route } from 'react-router-dom';
import ParcelComponent from 'single-spa-react/parcel';
import { mountRootParcel } from 'single-spa';
import { Home } from './components/home';

export default function Root() {
    return (
        <>
            <ParcelComponent
                config={System.import(
                    '@pulselive/core-product-components'
                ).then((widget) => widget.NotificationsParcel)}
                mountParcel={mountRootParcel}
            />

            <BrowserRouter>
                <Route path="/" exact>
                    <Home />
                </Route>
            </BrowserRouter>
        </>
    );
}

// type validation
Root.propTypes = {
    /**
     * Array of objects used to construct the nav items
     */
    applicationRoutes: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            pathname: PropTypes.string
        })
    )
};
